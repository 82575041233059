import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({data}) => {

    console.log( data );

// styles

const tile = "Atex Travel";
const description = "Travel information for traveling in the Stowmarket area, including getting to and from work.";
const publishDate = "2021-12-21";
const tags = "Suffolk travel business";
const img =  data.site.siteMetadata.siteUrl + data.site.siteMetadata.image;
const imgHeight = data.site.siteMetadata.imgHeight;
const imgWidth = data.site.siteMetadata.imgWidth;
const url = data.site.siteMetadata.siteUrl;

const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "image": {
        "@type": "ImageObject",
        "url": img,
        "width": imgWidth,
        "height": imgHeight
    },
    "name": "Atex Travel",
    "url": url,
    "description": description,
    "datePublished": publishDate,
            "publisher": {
                "@type": "Organization",
                "@id": url+"#organization",
                "url": url,
                "name": "Atex",
                "description": "Atex Business Park",
                "logo": {
                    "@type": "ImageObject",
                    "@id": url+"#logo",
                    "url": img,
                    "width": imgWidth,
                    "height": imgHeight
                },
            },
            "keywords": tags
        };


  return (
         <Layout>
        <SEO title="Atex Travel" description={description} date={publishDate} image={img} tags={tags}  schemaMarkup={schema} />
        <main>
<h1>Welcome to ATEX Travel</h1>
<p>Here you will find information about travel in the Stowmarket area, including getting to and from work. Please check back soon for the latest information.</p>
<ul>
<li>
<p><a href="https://bustimes.org/localities/stowmarket">Find my Bus Times</a></p>
</li>
<li>
<p><a href="https://www.greateranglia.co.uk/travel-information/live-departure-arrival-boards/SMK">Find my Train Times</a></p>
</li>
<li>
<p><a href="https://liftshare.com/uk/community/suffolk">Find my Car Share</a></p>
</li>
<li>
<p><a href="https://www.bikeregister.com">My Bicycle Security</a></p>
</li>
<li>
<p><a href="https://www.bike2workscheme.co.uk">Buy a Bicycle Tax Free</a></p>
</li>
<li>
<p><a href="https://www.suffolkonboard.com/cycle/stowmarket/">Cycle routes round Stowmarket</a></p>
</li>
<li>
<p><a href="https://www.routeyou.com/en-gb/location/bike/47295638/cycling-in-stowmarket-overview-of-all-cycle-routes/">Cycle routes round Stowmarket</a></p>
</li>     
<li>
<p><a href="https://www.openstreetmap.org/way/918174035">Find Atex Business Park</a></p>
</li>
<li>
<p><a href="https://osm.mathmos.net/prow/progress/suffolk/mid-suffolk/stowmarket/">Find My Footpaths</a></p>
</li>
</ul>        
        </main>
      <em>December 20, 2021</em>
        </Layout >
  )
}

export const pageQuery = graphql`
  query {
    site {
        siteMetadata {
            siteUrl
            image
            imageHeight
            imageWidth
            title
        }
    }
}`;





export default IndexPage
